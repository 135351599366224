import React, {PropsWithChildren, useMemo} from 'react';

import {useLocation} from 'react-router-dom';
import * as queryString from 'query-string';
import {Config} from 'src/Config';

export interface QueryContextProps {
  accessToken: string;
  coinifyApiBaseUrl: string;
  partnerId: number;
  partnerName: string;
  tradeId: string;
  verbose: boolean;
  testCard: number;
  testCardInvalid: boolean;
  transferInAmount: number;
  transferInHandlingFeeAmount: number;
  transferInFeeAmount: number;
  transferInCurrency: string;
  transferOutAmount: number;
  transferOutFeeAmount: number;
  transferOutCurrency: string;
  transactionTime: string;
  paymentId: string;
  email: string;
  country: string;
  billingAddress: {email: string; country: string};
  returnUrl: string;
  theme: string;
  supportedCards: string[];

  isAPMEnabled: boolean;
  paymentAggregateId: string;
}

export const QueryContext = React.createContext<QueryContextProps>({
  accessToken: '',
  coinifyApiBaseUrl: '',
  partnerId: undefined,
  partnerName: '',
  tradeId: undefined,
  verbose: false,
  testCard: undefined,
  testCardInvalid: true,
  transferInAmount: 0,
  transferInHandlingFeeAmount: 0,
  transferInFeeAmount: 0,
  transferInCurrency: '',
  transferOutAmount: 0,
  transferOutFeeAmount: 0,
  transferOutCurrency: '',
  transactionTime: '',
  paymentId: '',
  email: '',
  country: '',
  billingAddress: undefined,
  returnUrl: '',
  theme: '',
  supportedCards: undefined,

  isAPMEnabled: false,
  paymentAggregateId: '',
});

export const QueryProvider = (props: PropsWithChildren<{}>) => {
  const {search} = useLocation();
  const queryParams = useMemo(() => queryString.parse(search), [search]);

  const accessToken = queryParams.accessToken as string;
  const coinifyApiBaseUrl = decodeURIComponent(
    (queryParams.baseUrl as string) || Config.getDefaultUrl()
  );
  const partnerId = Config.asInt(queryParams.partnerId) || 36;
  const partnerName = Config.asString(queryParams.partnerName) || 'Partner';
  const tradeId = Config.asString(queryParams.tradeId);
  const verbose = Config.asBoolean(queryParams.verbose);
  let testCard = Config.asInt(queryParams.testCard);
  testCard = isFinite(testCard) ? testCard : 0;
  const testCardInvalid = Config.asBoolean(queryParams.testCardInvalid);
  const transferInAmount = Config.asFloat(queryParams.transferInAmount);
  const transferInHandlingFeeAmount = Config.asFloat(queryParams.transferInHandlingFee);
  const transferInFeeAmount = Config.asFloat(queryParams.transferInFeeAmount);
  const transferInCurrency = Config.asString(queryParams.transferInCurrency);
  const transferOutAmount = Config.asFloat(queryParams.transferOutAmount);
  const transferOutFeeAmount = Config.asFloat(queryParams.transferOutFeeAmount);
  const transferOutCurrency = Config.asString(queryParams.transferOutCurrency);
  const transactionTime = Config.asString(queryParams.transactionTime) || '1';
  const paymentId = Config.asString(queryParams.paymentId);
  const email = Config.asString(queryParams.email);
  const country = Config.asString(queryParams.country);
  const billingAddress = {email, country};
  const returnUrl = decodeURI(Config.asString(queryParams.returnUrl));
  const theme = Config.asString(queryParams.theme);
  const supportedCards = Config.asStringArray(queryParams.supportedCards);
  const isAPMEnabled = Config.asBoolean(queryParams.isAPMEnabled);
  const paymentAggregateId = Config.asString(queryParams.paymentAggregateId);

  const queryContext = useMemo(
    () => ({
      accessToken,
      coinifyApiBaseUrl,
      partnerId,
      partnerName,
      tradeId,
      verbose,
      testCard,
      testCardInvalid,
      transferInAmount,
      transferInHandlingFeeAmount,
      transferInFeeAmount,
      transferInCurrency,
      transferOutAmount,
      transferOutFeeAmount,
      transferOutCurrency,
      transactionTime,
      paymentId,
      email,
      country,
      billingAddress,
      returnUrl,
      theme,
      supportedCards,
      isAPMEnabled,
      paymentAggregateId,
    }),
    [
      accessToken,
      coinifyApiBaseUrl,
      partnerId,
      partnerName,
      tradeId,
      verbose,
      testCard,
      testCardInvalid,
      transferInAmount,
      transferInHandlingFeeAmount,
      transferInFeeAmount,
      transferInCurrency,
      transferOutAmount,
      transferOutFeeAmount,
      transferOutCurrency,
      transactionTime,
      paymentId,
      email,
      country,
      billingAddress,
      returnUrl,
      theme,
      supportedCards,
      isAPMEnabled,
      paymentAggregateId,
    ]
  );

  return (
    <QueryContext.Provider value={queryContext}>{props.children}</QueryContext.Provider>
  );
};
