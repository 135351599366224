import * as React from 'react';

import './HeartbeatLoader.css';

interface HeartbeatLoaderProps {
  widthAndHeight: number;
  bgColor: string;
}

export class HeartbeatLoader extends React.Component<HeartbeatLoaderProps, any> {
  private wh = '20px';
  private bgColor = 'white';
  constructor(props: HeartbeatLoaderProps) {
    super(props);
    this.wh = String(props.widthAndHeight || 20) + 'px';
    this.bgColor = props.bgColor || 'white';
  }
  public render() {
    return (
      <div className='heartbeat-loader' style={{width: this.wh, height: this.wh}}>
        <div>
          <div
            className='heartbeat-circle circle-1'
            style={{width: this.wh, height: this.wh, backgroundColor: this.bgColor}}
          />
        </div>
        <div>
          <div
            className='heartbeat-circle circle-2'
            style={{width: this.wh, height: this.wh, backgroundColor: this.bgColor}}
          />
        </div>
      </div>
    );
  }
}
