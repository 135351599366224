import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import registerServiceWorker, {
  unregister as unregisterServiceWorker,
} from './registerServiceWorker';

ReactDOM.render(<App />, document.getElementById('root') as HTMLElement);

if (window.location.href.indexOf('localhost') >= 0) {
  registerServiceWorker();
} else {
  unregisterServiceWorker();
}
