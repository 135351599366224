/* eslint-disable */
// todo: change this form...

import * as React from 'react';
import {Subscription} from 'rxjs';

import Currency from '@coinify/currency';

import {Config} from '../../Config';
import {CrossFrameCommunication} from '../../CrossFrameCommunication';
import {Logger} from '../../Logger';
import {PaymentController} from '../../PaymentController';
import {DotLoader} from '../DotLoader';
import {Input} from '../Form';
import {HeartbeatLoader} from '../HeartbeatLoader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLock} from '@fortawesome/free-solid-svg-icons';

const CLEAN_STATE = {
  saveCard: false,
  submittingForm: false,
  errorMessage: '',
  buttonEnabled: false,
  cardType: '',
  tradeId: '',
  values: {
    cardHolderName: '',
    cardNumber: '',
    expireMonth: '',
    expireYear: '',
    cvv: ''
  },
  ok: {
    cardNumber: false,
    cvv: false,
    cardHolderName: false,
    expireMonth: false,
    expireYear: false
  },
  touched: {
    cardNumber: false,
    cvv: false,
    cardHolderName: false,
    expireMonth: false,
    expireYear: false
  },
  submitSuccess: false,
  cancelled: false
};

export class CardDataForm extends React.Component<{}, any> {
  get buttonEnabled(): boolean {
    return this.state.buttonEnabled;
  }
  public input: any;

  // nested state is evil wtf : todo change :) 
  public state = CLEAN_STATE;

  public cardNumberMask = [
    {
      mask: '0000 000000 00000',
      regex: '^3[47][0-9]{13}$',
      cardtype: 'american express'
    },
    {
      mask: '0000 0000 0000 0000',
      regex: '^(?:6011|65d{0,2}|64[4-9]d?)d{0,12}',
      cardtype: 'discover'
    },
    {
      mask: '0000 000000 0000',
      regex: '^3(?:0([0-5]|9)|[689]d?)d{0,11}',
      cardtype: 'diners'
    },
    {
      mask: '0000 0000 0000 0000',
      regex:
        '^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$',
      cardtype: 'mastercard'
    },
    {
      mask: '0000 000000 00000',
      regex: '^(?:2131|1800)d{0,11}',
      cardtype: 'jcb15'
    },
    {
      mask: '0000 0000 0000 0000',
      regex: '^(?:35d{0,2})d{0,12}',
      cardtype: 'jcb'
    },
    {
      mask: '0000 0000 0000 0000',
      regex: '^(?:5[0678]d{0,2}|6304|67d{0,2})d{0,12}',
      cardtype: 'maestro'
    },
    {
      mask: '0000 0000 0000 0000',
      regex: '^4d{0,15}',
      cardtype: 'visa'
    },
    {
      mask: '0000 0000 0000 0000',
      regex: '^62d{0,14}',
      cardtype: 'unionpay'
    },
    {
      mask: '0000 0000 0000 0000',
      cardtype: 'Unknown'
    }
  ];

  public expireMonthMask = [
    {
      mask: '00',
      min: 1,
      max: 12
    }
  ];

  public expireYearMask = [
    {
      mask: '00'
    }
  ];

  public cvvMask = [
    {
      mask: '0000'
    }
  ];

  public subscriptions: Subscription[] = [];

  public autoFillTimer;

  constructor(props: any) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  private static toCurrencySymbol(useSymbol: boolean, currency: string): string {
    const conv = {
      EUR: '€',
      GBP: '£',
      USD: '$'
      // BTC: '₿'
    } as any;
    return (useSymbol && conv[currency]) || currency || '';
  }

  private static toFixedCurrencyDecimals(amount: number, currency: string): string {
    if (Currency.isValidCurrency(currency)) {
      const numberOfDecimals: number = Currency.getDecimalsForCurrency(currency);
      const base = 10;
      amount = Number.parseFloat('' + amount);
      const pow = Math.pow(base, numberOfDecimals);
      amount = Math.round(amount * pow) / pow;
      return amount.toFixed(numberOfDecimals);
    } else {
      return amount.toString();
    }
  }

  private static formatAmount(
    currency: string,
    amount: number,
    useCurrencySymbol = true
  ) {
    return (
      CardDataForm.toCurrencySymbol(useCurrencySymbol, currency) +
      ' ' +
      CardDataForm.toFixedCurrencyDecimals(amount, currency)
    );
  }

  public componentDidMount() {
    this.setState(this.state);
    this.resetForm();
    this.subscriptions.push(
      // observer type takes unknown as param
      PaymentController.tradeId.subscribe((tradeId: unknown) => {
        this.setState({tradeId}, () => {
          if (!this.state.tradeId) {
            this.showError(PaymentController.MSG_NO_TRADE_ID);
          }
        });
      })
    );
    this.subscriptions.push(
      PaymentController.errorMessage.subscribe((errMsg: string) => {
        this.showError(errMsg);
      })
    );
    this.autoFillTimer = window.setInterval(() => this.checkAutoFill(), 1000);
  }

  public componentWillUnmount() {
    window.clearInterval(this.autoFillTimer);
    delete this.autoFillTimer;
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.subscriptions = [];
  }

  public showError(errorMessage: string) {
    this.setState({errorMessage});
  }

  public async handleSubmit(e: React.FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault();

    if (!this.buttonEnabled) {
      Logger.log('Ignored submit button is not enabled.');
      return null;
    }

    if (this.state.submittingForm) {
      Logger.error('Already submitting form.');
      return;
    }

    this.setState({submittingForm: true});
    let submitSuccess: boolean;
    let tradePayment;
    try {
      const {
        cardHolderName,
        cardNumber,
        expireMonth,
        expireYear,
        cvv
      } = this.state.values;
      const {billingAddress} = Config.get();
      tradePayment = await PaymentController.registerCardAndCreateTradePayment(
        cardHolderName,
        cardNumber,
        expireMonth,
        expireYear,
        cvv,
        billingAddress.email,
        billingAddress.country
      );
      submitSuccess = !!tradePayment;
    } catch (e) {
      Logger.error(e);
      // If there were an exception happening at this high level point
      // we can show an error here.
      if (!this.state.errorMessage) {
        this.setState({errorMessage: PaymentController.MSG_UNKNOWN_ERROR});
      }
      submitSuccess = false;
    }
    this.setState({submittingForm: false});
    if (submitSuccess) {
      PaymentController.processTradePayment(tradePayment);
    }
  }

  public onMaskCompleteCard(maskRef): void {
    const {supportedCards} = Config.get();

    this.setState({
      cardType:
        maskRef &&
        maskRef.masked &&
        maskRef.masked.currentMask &&
        supportedCards.includes(maskRef.masked.currentMask.cardtype)
          ? maskRef.masked.currentMask.cardtype
          : ''
    });
  }

  public onFormValueChanged(name: string, value: string): void {

    const touched = {...this.state.touched}
    touched[name] = true;
    this.setState({touched})

    const values = {...this.state.values}
    values[name] = value;

    this.setState({values}, () => {
      const ok = {...this.state.ok}
      ok.cardNumber = this.isCard(this.state.values.cardNumber);
      ok.cvv = this.inRange(this.state.values.cvv, 1, 9999);
      ok.cardHolderName = this.isStringMinLen(this.state.values.cardHolderName, 1);
      ok.expireMonth = this.inRange(this.state.values.expireMonth, 1, 12);
      ok.expireYear = this.inRange(this.state.values.expireYear, 18, 99);

      this.setState({ok}, ()=> {
        this.setState({buttonEnabled: (
          this.state.ok.cardNumber &&
          this.state.ok.cvv &&
          this.state.ok.cardHolderName &&
          this.state.ok.expireMonth &&
          this.state.ok.expireYear)});
      });
    })
  }

  public fieldInvalid(name: string): boolean {
    if (!this.state.touched[name]) {
      return false;
    }
    return !this.state.ok[name];
  }

  public render() {
    if (this.state.cancelled) {
      return <div />;
    }
    const {
      partnerName,
      theme,
      transferInAmount,
      transferInFeeAmount,
      transferInHandlingFeeAmount,
      transferInCurrency,
      transferOutAmount,
      transferOutFeeAmount,
      transferOutCurrency,
      transactionTime,
      supportedCards
    } = Config.get();

    const useCurrencySymbol = theme !== 'trade-widget';

    const transferInAmountFormatted = CardDataForm.formatAmount(
      transferInCurrency,
      transferInAmount,
      useCurrencySymbol
    );
    const transferInAmountBeforePaymentFeeFormatted = CardDataForm.formatAmount(
      transferInCurrency,
      transferInAmount - Math.abs(transferInFeeAmount),
      useCurrencySymbol
    );
    const transferInPartnerFeeFeeAmount = transferInFeeAmount - transferInHandlingFeeAmount;
    const transferInPartnerFeeFeeAmountFormatted = CardDataForm.formatAmount(
      transferInCurrency,
      transferInPartnerFeeFeeAmount,
      useCurrencySymbol
    );
    const transferInHandlingFeeAmountFormatted = CardDataForm.formatAmount(
      transferInCurrency,
      transferInHandlingFeeAmount,
      useCurrencySymbol
    );
    const transferOutAmountFormatted = CardDataForm.formatAmount(
      transferOutCurrency,
      transferOutAmount,
      useCurrencySymbol
    );
    const transferOutFeeAmountFormatted = CardDataForm.formatAmount(
      transferOutCurrency,
      transferOutFeeAmount,
      useCurrencySymbol
    );
    const transferOutBeforeFeesAmountFormatted = CardDataForm.formatAmount(
      transferOutCurrency,
      transferOutAmount + Math.abs(transferOutFeeAmount),
      useCurrencySymbol
    );
    const transactionTimeFormatted =
      '~' +
      transactionTime +
      ' hour' +
      (Number.parseFloat(transactionTime) > 1 ? 's' : '');
    const hideAmountToPurchaseAndTransactionFee = !isFinite(transferOutFeeAmount)
      ? 'none'
      : 'flex';
    const submittingForm = this.state.submittingForm;
    const renderHeartbeatLoader = submittingForm && Config.shouldUseHeartbeatLoader;
    const renderDotLoader = submittingForm && !Config.shouldUseHeartbeatLoader;
    const renderContinueButton = (transferInAmountFormatted) => (
      <button
        form='card'
        className={
          'card-data-continue-button button primary ' +
          (this.buttonEnabled && !submittingForm ? '' : ' disabled')
        }
        type='submit'
      >
        {!submittingForm && (
          <>
            <FontAwesomeIcon icon={faLock} />{' '}
            <span>Pay {transferInAmountFormatted}</span>
          </>
        )}
        {renderHeartbeatLoader && (
          <HeartbeatLoader widthAndHeight={20} bgColor={'white'} />
        )}
        {renderDotLoader && <DotLoader widthAndHeight={20} bgColor={null} />}
      </button>
    );
    const renderCancelButton = () => (
      <button
        className={
          'card-data-card-info-cancel-button button default ' +
          (this.buttonEnabled && !submittingForm ? '' : ' disabled')
        }
        onClick={this.cancelClicked}
      >
        {!submittingForm && <span>Cancel</span>}
        {renderHeartbeatLoader && (
          <HeartbeatLoader widthAndHeight={20} bgColor={'white'} />
        )}
        {renderDotLoader && <DotLoader widthAndHeight={20} bgColor={null} />}
      </button>
    );

    const errorMessageLines = (this.state.errorMessage || '')
      .trim()
      .split('\n')
      .filter(x => x);

    return (
      <div>
        {this.state.errorMessage && (
          <div
            className='card-data-error-message-container'
            style={{flexDirection: errorMessageLines.length > 1 ? 'column' : 'row'}}
          >
            {errorMessageLines.map((x, i) => (
              <div key={i} id='desc' className='desc'>
                {x}
              </div>
            ))}
          </div>
        )}
        <form
          id='card'
          className='card-data'
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => this.handleSubmit(e)}
        >
          <div className='card-data-column-left'>
            <p className='card-column-header'>Payment details</p>

            <label className='card-field-label'>
              Name on card
              <Input
                ref='cardHolderName'
                className={
                  'card-data-input-field ' +
                  (this.fieldInvalid('cardHolderName') ? 'invalid ' : '')
                }
                type='text'
                placeholder='Card holders name'
                onInput={e => this.onFormValueChanged('cardHolderName', e.target.value)}
              />
            </label>
            <label className='card-field-label'>
              Card number
              <Input
                ref='cardNumber'
                className={
                  'card-data-input-field ' +
                  (this.fieldInvalid('cardNumberMask') ? 'invalid ' : '')
                }
                type='text'
                placeholder={supportedCards
                  .map(
                    cardName => cardName.charAt(0).toUpperCase() + cardName.substring(1)
                  )
                  .join(', ')}
                  onInput={e => this.onFormValueChanged('cardNumber', e.target.value)}
                mask={this.cardNumberMask}
                onMaskComplete={maskRef => this.onMaskCompleteCard(maskRef)}
              />
            </label>

            <div className='card-data-row'>
              <span className='card-expire-section'>
                <label className='card-mmYYcvv card-field-label no-line-break card-data-field-margin'>
                  Month
                  <Input
                    ref='expireMonth'
                    className={
                      'card-data-input-field ' +
                      (this.fieldInvalid('expireMonth') ? 'invalid ' : '') +
                      'card-data-max-w1'
                    }
                    type='text'
                    placeholder='MM'
                    onInput={e => this.onFormValueChanged('expireMonth', e.target.value)}
                    mask={this.expireMonthMask}
                  />
                </label>
                <label className='card-mmYYcvv card-field-label no-line-break card-data-field-margin'>
                  Year
                  <Input
                    ref='expireYear'
                    className={
                      'card-data-input-field ' +
                      (this.fieldInvalid('expireYear') ? 'invalid ' : '') +
                      'card-data-max-w2'
                    }
                    type='text'
                    placeholder='YY'
                    onInput={e => this.onFormValueChanged('expireYear', e.target.value)}
                    mask={this.expireYearMask}
                  />
                </label>
              </span>
              <label className='card-mmYYcvv card-field-label no-line-break'>
                CVV
                <Input
                  ref='cvv'
                  className={
                    'card-data-input-field ' +
                    (this.fieldInvalid('cvvOk') ? 'invalid ' : '') +
                    'card-data-max-w3'
                  }
                  type='text'
                  placeholder='CVV'
                  onInput={e => this.onFormValueChanged('cvv', e.target.value)}
                  mask={this.cvvMask}
                />
              </label>
            </div>
          </div>
          {/* KRM: The following block renders the order break down - consider adding it to a seperate component */}
          <div className='card-data-column-right'>
            <span className='card-order-container'>
              <p className='card-column-header'>Your purchase</p>
              <div
                className='card-order-line'
                style={{display: hideAmountToPurchaseAndTransactionFee}}
              >
                <span className='card-order-item'>Amount to Purchase</span>
                <span className='card-order-price'>
                  {transferOutBeforeFeesAmountFormatted}
                </span>
              </div>
              <div
                className='card-order-line'
                style={{display: hideAmountToPurchaseAndTransactionFee}}
              >
                <div style={{display: 'inline-flex'}}>
                  <span className='card-order-item'>Network fee</span>
                </div>
                <span className='card-order-price'>{transferOutFeeAmountFormatted}</span>
              </div>
              <div className='card-order-line'>
                <span className='card-order-item'>To be Received</span>
                <span className='card-order-price'>
                  {(transferOutCurrency.toUpperCase() !== 'BTC' ? '~' : '') +
                    transferOutAmountFormatted}
                </span>
              </div>
              <div className='card-order-divider' />
              <div className='card-order-line'>
                <span className='card-order-item'>Amount</span>
                <span className='card-order-price'>
                  {transferInAmountBeforePaymentFeeFormatted}
                </span>
              </div>
              <div className='card-order-line'>
                <div style={{display: 'inline-flex'}}>
                  <span className='card-order-item'>Handling fee</span>
                </div>
                <span className='card-order-price'>{transferInHandlingFeeAmountFormatted}</span>
              </div>
              {!!transferInPartnerFeeFeeAmount && (
                <div className='card-order-line'>
                  <div style={{display: 'inline-flex'}}>
                    <span className='card-order-item'>{partnerName} fee</span>
                  </div>
                  <span className='card-order-price'>{transferInPartnerFeeFeeAmountFormatted}</span>
                </div>
              )}
              <div className='card-order-line'>
                <span className='card-order-item bold'>Total cost</span>
                <span className='card-order-price bold'>{transferInAmountFormatted}</span>
              </div>
              <div className='card-order-divider' />
              <div className='card-order-line card-order-price-line'>
                <span className='card-order-item'>Transaction time</span>
                <span className='card-order-price'>{transactionTimeFormatted}</span>
              </div>
            </span>

            {theme !== 'trade-widget' && renderContinueButton(transferInAmountFormatted)}
          </div>
          {/* End of order breakdown */}
        </form>

        {theme === 'trade-widget' && (
          <div className='grid row'>
            {renderCancelButton()}
            {renderContinueButton(transferInAmountFormatted)}
          </div>
        )}
      </div>
    );
  }

  private resetForm() {
    console.log('RESETTING FORM');
    const cfg = Config.get();

    // TODO: Consider remove this from distro. Not needed.
    if (cfg.testCard >= 1) {
      const invalidCardNo = '1234 1234 1234 1234';
      const testCards = [
        '4012 0010 3749 0014',
        '4012 0010 3749 0006',
        '4012 0010 3748 4447',
        '4012 0010 3746 1114',
        '4012 0010 3716 7778',
        '5453 0100 0008 3303', // Mastercard.
        '4012 0010 3685 3337',
        '4012 0010 3629 8889',
        '4012 0010 3848 8884',
        '4012 0010 3844 3335',
        '4012 0010 3627 5556'
      ];
      window.setTimeout(() => {
        (this.refs.cardHolderName as any).selfRef.value = 'John doe';
        console.log(cfg);
        console.log("Filling up card number: ", cfg.testCardInvalid
        ? invalidCardNo
        : testCards[cfg.testCard - 1]);
        (this.refs.cardNumber as any).selfRef.value = cfg.testCardInvalid
          ? invalidCardNo
          : testCards[cfg.testCard - 1];
        (this.refs.expireMonth as any).selfRef.value = '10';
        (this.refs.expireYear as any).selfRef.value = '20';
        (this.refs.cvv as any).selfRef.value = '123';
      }, 50);
    }
    this.setState(CLEAN_STATE);
  }

  private checkAutoFill() {
    for (const k in this.state.values) {
      if (!this.state.values[k] && !this.state.touched[k]) {
        if (!this.refs[k]) {
          continue;
        }
        const value = (this.refs[k] as any).selfRef.value || '';
        if (value.length > 0) {
          this.onFormValueChanged(k, value);
        }
      }
    }
    // HACK: Fix copy paste issue.
    // TODO: come up with a proper solution for this issue.
    const id = 'cardNumber';
    if (!this.refs[id]) {
      return;
    }
    const val = (this.refs[id] as any).selfRef.value;
    if (this.state.values.cardNumber !== val) {
      const values = {...this.state.values};
      values.cardNumber = val;
      this.setState(values);
      this.onFormValueChanged(id, val);
    }
  }

  private inRange = (rangeValue: string, min: number, max: number): boolean => {
    const no = Number.parseInt(rangeValue);
    if (Number.isNaN(no) || !Number.isFinite(no)) {
      return false;
    }
    return !!rangeValue && no >= min && no <= max;
  };

  private isCard = (cardValue: string): boolean => {
    cardValue = (cardValue || '').replace(/ /g, '');
    const no = parseInt(cardValue);
    if (isNaN(no) || !isFinite(no)) {
      return false;
    }
    return cardValue && cardValue.length >= 16 && !!this.state.cardType;
  };

  private isStringMinLen = (inputString: string, min: number): boolean => {
    if (!inputString) {
      return false;
    }

    inputString = inputString.trim();

    return inputString && inputString.length >= min;
  };

  private cancelClicked = () => {
    this.setState({cancelled: true});
    CrossFrameCommunication.postCancel();
  };
}
