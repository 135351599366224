import {
  BillingAddress,
  CardData,
  UrlData,
  validateCardData,
  validateUrlData,
} from 'src/models';
import CoinifyHttp from './CoinifyHttp';

console.log('*** Coinify PSP Library ***');

enum PSPType {
  safecharge = 'safecharge',
  isignthis = 'isignthis',
}

export class Coinify {
  public static urls = {
    threeDSecureCallback: 'https://app.coinify.com/safecharge/callback.html?pares',
    hostedPaymentPageCallback: 'www.google.com',
    storeCardPayload1: '/cards/store-card-payload', // used when accessing with accessToken.
    storeCardPayload2: '/cards/session-token', // used when accessing with tradeId.
    finalizePayment: '/cards/finalize-payment',
    cards: '/cards',
    payments: '/payments',
    createTradePaymentAttempt: '/payment-attempts',
    getPaymentAttemptState: (paymentId: string) =>
      '/payment-attempts/' + paymentId + ' /state',
  };

  public static http = new CoinifyHttp();

  public overlay: any = undefined;
  public loadingOverlay: any = undefined;
  public container3ds: any = undefined;

  public callbackUrl3DS = 'localhost:6564';
  public callbackUrlPayment = 'localhost:1234';
  public coinifyApiBaseUrl = 'http://localhost:8087';

  public container3dsForm: any = undefined;
  public container3dsi1: any = undefined;
  public container3dsi2: any = undefined;
  public container3dsFrame: any = undefined;

  public istBaseUrl = 'https://verify.isignthis.com';

  public containerPay: any = undefined;

  public iSignThis: any;

  public containerIsOverlay = false;

  public cssLoaded = false;

  public provider = {
    safecharge: {
      loaded: false,
      loading: false,
    },
    isignthis: {
      loaded: false,
      loading: false,
    },
  };

  private options: any = {
    verbose: true,
    accessToken: '',
    accessTradeId: '',
  };

  /**
   * Used to apply the card on a tradeInfo object.
   * It does so by adding it to the details.
   */
  public static applyCardToTradeTransferInDetails(tradeInfo: any, atbs: any): any {
    const provider = atbs.psp || atbs.provider;
    Coinify.assertProvider(provider);
    if (!tradeInfo || !tradeInfo.transferIn) {
      throw new Error('Invalid transfer In in trade info.');
    }

    const details = {
      card: {},
    };
    if (atbs.cardExternalId) {
      (details.card as any).cardExternalId = atbs.cardExternalId;
    } else if (atbs.ccTempToken) {
      (details.card as any).ccTempToken = atbs.ccTempToken;
      (details.card as any).returnUrl =
        atbs.returnUrl || 'https://app.sandbox.coinify.com/';
    } else {
      // TODO extend with token support.
      throw new Error(
        'cardExternalId or ccTempToken was not present amongst the attributes when applying card data'
      );
    }

    tradeInfo.transferIn.details = details;
    return tradeInfo;
  }

  private static assertProvider(provider: string) {
    if (provider !== undefined && provider !== PSPType.safecharge) {
      throw new Error('Invalid psp: ' + provider);
    }
  }

  public openPaymentUrl(urlData: UrlData, pspType: string, container: any) {
    pspType = this.validatePSP(pspType);
    validateUrlData(urlData);
    this.containerIsOverlay = false;
    if (!container) {
      container = this.createOverlay();
      this.containerIsOverlay = true;
      this.showOverlay();
    }
    return new Promise((resolve, reject) => {
      let frame;
      if (urlData.is3DS) {
        const callbackUrl = urlData.callbackUrl || this.callbackUrl3DS;
        frame = this.create3DSFrame(urlData.url, urlData.paRequest, callbackUrl, resolve);
      } else {
        // const callbackUrl = urlData.callbackUrl || this.callbackUrlPayment;
        frame = this.createPaymentFrame(urlData.url, resolve);
      }
      container.appendChild(frame);
    });
  }

  public log(text: any, obj?: any) {
    if (this.options.verbose) {
      console.log('Coinify:', text, obj);
    }
  }

  private get Safecharge() {
    return (window as any).Safecharge;
  }

  private get accessWithTradeId(): boolean {
    return !!this.options.accessTradeId;
  }

  public showLoadingOverlay(value = true) {
    const overlay = this.createLoadingOverlay();
    if (value || value === undefined) {
      overlay.classList.remove('c-is-hidden');
    } else {
      overlay.classList.add('c-is-hidden');
    }
  }

  public isProviderLoaded(pspType: string): boolean {
    pspType = this.validatePSP(pspType);
    if (pspType === PSPType.isignthis) {
      return !!this.iSignThis;
    }
    return !!this.Safecharge;
  }

  public setOptions(opts: any) {
    this.log('Setting option(s) ' + (opts ? JSON.stringify(opts) : 'undefined'));
    if (!this.options) {
      this.options = opts;
    } else if (opts) {
      for (const key of Object.keys(opts)) {
        this.options[key] = opts[key];
      }
    }
    if (!this.options) {
      this.log('Failed to set options.');
      return;
    }
    if (this.options.coinifyApiBaseUrl) {
      this.log('Setting Coinity API base url : ' + this.options.coinifyApiBaseUrl);
      this.coinifyApiBaseUrl = this.options.coinifyApiBaseUrl;
    }
    if (this.options.default3DSCallback) {
      this.log('Setting Default 3DS callback url : ' + this.options.default3DSCallback);
      this.callbackUrl3DS = this.options.default3DSCallback;
    }
    if (this.options.defaultHostedPaymentCallback) {
      this.log(
        'Setting trade service url : ' + this.options.defaultHostedPaymentCallback
      );
      this.callbackUrlPayment = this.options.defaultHostedPaymentCallback;
    }
  }

  public registerCard(options: {
    card: CardData;
    saveCard: boolean;
    billingAddress: BillingAddress;
  }) {
    const cardData = options.card as CardData;
    const billingAddress = options.billingAddress as BillingAddress;
    this.log('Register card options: ', options);

    if (!cardData) {
      throw new Error('No card data');
    }
    const saveCard = !!options.saveCard;
    validateCardData(cardData);

    this.log(
      'Registering card; saving card: ' +
        (saveCard ? 'persistent' : 'temporary') +
        '; retrieving store card payload'
    );
    this.log('before promise');
    return new Promise<any>((resolve, reject) => {
      // Retrieve a store card payload in order to store the card.
      this.getStoreCardPayload()
        .then((storeCardsPayloadResponse: any) => {
          let payload;
          if (this.accessWithTradeId) {
            payload = Object.assign({}, storeCardsPayloadResponse);
          } else {
            payload = Object.assign({}, storeCardsPayloadResponse.payload);
          }
          payload.sessionToken =
            payload.sessionToken || storeCardsPayloadResponse.sessionToken;
          const provider = (storeCardsPayloadResponse.psp ||
            storeCardsPayloadResponse.provider ||
            'safecharge') as string;
          Coinify.assertProvider(provider);
          payload.cardData = Object.assign(payload.cardData || {}, cardData);
          this.log('Registering card; Requesting ccTempToken');
          payload.billingAddress = Object.assign(
            payload.billingAddress || {},
            billingAddress
          );

          // Create a tempoary token,
          this.createTemporaryCardToken(payload, provider)
            .then((tokenResponse: any) => {
              tokenResponse = tokenResponse || {};
              this.log(
                'Registering card; Retrieved ccTempToken ' + tokenResponse.ccTempToken
              );
              const status = tokenResponse.status || '';
              if (status.toLowerCase() === 'success') {
                // If we want to save the card for further purchases
                if (saveCard) {
                  this.log('Registering card; saving cTempToken');
                  this.saveCardByTempToken(
                    tokenResponse.ccTempToken,
                    payload.sessionToken
                  )
                    .then((saveCardResponse: any) => {
                      this.log('Saved card: ' + status);
                      resolve(saveCardResponse);
                    })
                    .catch(reject);

                  // Else just return the temporary card token ( intended for paying with a token that gets spend and thrown away )
                } else {
                  tokenResponse.sessionToken = payload.sessionToken;
                  resolve(tokenResponse);
                }
              } else {
                let errorMessage = tokenResponse.error
                  ? tokenResponse.error.message
                  : null;
                console.error('Registering card; ', errorMessage);
                if (errorMessage === 'Missing or invalid CardData data') {
                  errorMessage = 'Invalid card';
                }
                reject({status, errorMessage});
              }
            })
            .catch((createTemporaryCardTokenError) => {
              reject(createTemporaryCardTokenError);
            });
        })
        .catch((httpError) => {
          reject(this.parseError(httpError));
        });
    });
  }

  public open3DSecureUrlForTradeAndFinalizePayment(
    createTradeResponseTransferInDetails: any,
    container: any = null
  ): Promise<any> {
    const details = createTradeResponseTransferInDetails;
    // const provider = this.validatePSP( details.psp || details.provider );

    if (!details.acsUrl) {
      throw new Error('TransferIn details did not contain an acsUrl');
    }

    if (!details.paRequest) {
      throw new Error('TransferIn details did not contain a paRequest');
    }

    const finalizeTradeArgs = {
      paResponse: '',
      tradeId: details.tradeId,
      CVV: details.CVV,
    };

    Object.keys(finalizeTradeArgs).forEach((x) => {
      if (finalizeTradeArgs[x] === undefined) {
        throw new Error('Missing argument ' + x);
      }
    });

    return new Promise<any>((resolve, reject) => {
      // TODO: Impl support to accept non-3ds cards as well.
      const urlData = {
        url: details.acsUrl,
        paRequest: details.paRequest,
        is3DS: true,
        // set callback url // Fallback on the local url if a url is not given in the details.;
        callbackUrl: details.threeDSecureCallback || Coinify.urls.threeDSecureCallback,
      };

      this.log('Opening payment url');
      this.openPaymentUrl(urlData, 'safecharge', container)
        .then((paResponse: any) => {
          if (!paResponse) {
            console.error('Failed to retrieve 3DS response.');
            reject({error: 'failed to auth3d', error_code: -5435});
          } else {
            finalizeTradeArgs.paResponse = paResponse.toString();
            this.finalizePayment(finalizeTradeArgs)
              .then((finalizePaymentResponse: any) => {
                this.log(
                  'Payment Result: ' +
                    finalizePaymentResponse.status +
                    ' : ' +
                    finalizePaymentResponse.reason
                );
                resolve(finalizePaymentResponse);
              })
              .catch((errFinalizePayment) => {
                reject(errFinalizePayment);
              });
          }
        })
        .catch((errOpenUrl) => {
          reject(errOpenUrl);
        });
    });
  }

  public open3DSecureUrlForTrade(
    createTradeResponseTransferInDetails: any,
    container: any = null
  ): Promise<any> {
    const details = createTradeResponseTransferInDetails;

    if (!details.acsUrl) {
      throw new Error('TransferIn details did not contain an acsUrl');
    }

    if (!details.paRequest) {
      throw new Error('TransferIn details did not contain a paRequest');
    }

    const finalizeTradeArgs = {
      paResponse: '',
      tradeId: details.tradeId,
      CVV: details.CVV,
    };

    Object.keys(finalizeTradeArgs).forEach((x) => {
      if (finalizeTradeArgs[x] === undefined) {
        throw new Error('Missing argument ' + x);
      }
    });

    return new Promise<any>((resolve, reject) => {
      // TODO: Impl support to accept non-3ds cards as well.
      const urlData = {
        url: details.acsUrl,
        paRequest: details.paRequest,
        is3DS: true,
        // set callback url // Fallback on the local url if a url is not given in the details.;
        callbackUrl: details.threeDSecureCallback || Coinify.urls.threeDSecureCallback,
      };

      this.log('Opening payment url');
      this.openPaymentUrl(urlData, 'safecharge', container)
        .then((paResponse: any) => {
          if (!paResponse) {
            console.error('Failed to retrieve 3DS response.');
            reject({error: 'failed to auth3d', error_code: -5435});
          } else {
            finalizeTradeArgs.paResponse = paResponse.toString();
            resolve(finalizeTradeArgs);
          }
        })
        .catch((errOpenUrl) => {
          reject(errOpenUrl);
        });
    });
  }

  public getCardList() {
    return new Promise<any>((resolve, reject) => {
      Coinify.http
        .get(this.uri(Coinify.urls.cards), this.options.accessToken)
        .then((cardList: any) => {
          resolve(cardList);
        })
        .catch((getError) => {
          reject(getError);
        });
    });
  }

  public async createTradePaymentAttempt(opts: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (!opts.paymentId) {
        reject('paymentId is invalid');
        return;
      }
      if (!opts.lastFour) {
        reject('lastFour is invalid');
        return;
      }
      if (!opts.name) {
        reject('name is invalid');
        return;
      }
      if (!opts.bin) {
        reject('bin is invalid');
        return;
      }
      if (!opts.sessionToken) {
        reject('sessionToken is invalid');
        return;
      }
      if (!opts.CVV) {
        reject('CVV is invalid');
        return;
      }
      if (!(opts.ccTempToken || opts.cardExternalId)) {
        reject('ccTempToken|cardExternalId is invalid');
        return;
      }
      const args = {
        paymentId: opts.paymentId,
        card: {
          name: opts.name,
          lastFour: opts.lastFour,
          bin: opts.bin,
          sessionToken: opts.sessionToken,
          CVV: opts.CVV,
          ccTempToken: opts.ccTempToken,
          cardExternalId: opts.cardExternalId,
        },
      };
      const url = this.uri(Coinify.urls.createTradePaymentAttempt);
      Coinify.http
        .post(url, args, this.options.accessToken)
        .then((response: any) => {
          resolve(response);
        })
        .catch((httpError) => {
          reject(this.parseError(httpError));
        });
    });
  }

  public async getPaymentAttemptState(paymentId: string) {
    return new Promise<any>((resolve, reject) => {
      const url = this.uri(Coinify.urls.getPaymentAttemptState(paymentId));
      Coinify.http
        .get(url)
        .then((response: any) => {
          resolve(response);
        })
        .catch((httpError) => {
          reject(this.parseError(httpError));
        });
    });
  }

  /**
   * finalizes the payment after 3D secure step has been passed.
   */
  public finalizePayment(atbs: {paResponse: string; tradeId: string; CVV: string}) {
    if (!atbs.paResponse) {
      throw new Error('Invalid argument; paResponse is not defined');
    }

    return new Promise((resolve, reject) => {
      this.log('Finalizing trade.');
      Coinify.http
        .post(this.uri(Coinify.urls.finalizePayment), atbs, this.options.accessToken)
        .then((response: any) => {
          this.log('Finalized payment for trade.');
          resolve(response);
        })
        .catch((httpError) => {
          reject(this.parseError(httpError));
        });
    });
  }

  public async getAPMUrl(aggregateId: string) {
    if (!aggregateId) {
      throw new Error('No aggregateId');
    }
    this.log('Fetching APM URL');
    try {
      const {paymentURL} = (await Coinify.http.get(
        this.uri(`${Coinify.urls.payments}/${aggregateId}/apm-payment-url`),
        this.options.accessToken
      )) as {paymentURL: string};

      this.log('Fetched APM URL: ', paymentURL);

      return paymentURL;
    } catch (httpError) {
      this.parseError(httpError);
    }
  }

  private parseError(httpError: any): any {
    let errorObject = httpError;
    if (errorObject && errorObject.body) {
      const tmp = JSON.parse(errorObject.body);
      if (tmp) {
        errorObject = tmp;
      }
    }
    return errorObject;
  }

  private uri(path: string): string {
    if (path[0] !== '/') {
      path = '/' + path;
    }
    return this.coinifyApiBaseUrl + path;
  }

  private createOverlay() {
    if (this.overlay) {
      return this.overlay;
    }
    this.log('Creating overlay');
    const o = (this.overlay = document.createElement('div'));
    o.className = 'c-overlay c-is-hidden';
    o.id = 'c-overlay';
    const body = document.getElementsByTagName('body')[0];

    body.appendChild(o);
    this.ensureCSSLoaded();
    return o;
  }

  private ensureCSSLoaded() {
    if (this.cssLoaded) {
      return;
    }
    const css = `
      .c-is-hidden {
        display: none;
      }
      .c-button-close {
        display: inline-block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAowAAAKMB8MeazgAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAB5SURBVDiNrZPRCcAwCEQfnUiySAZuF8kSWeH6Yz8KrQZMQAicJ+epAB0YwAmYJKIADLic0/GPPCbQAnLznCd/4NWUFfkgy1VjH8CryA95ApYltAiTRCZxpuoW+gz9WXE6NPeg+ra1UDIxGlWEObe4SGxY5fIxlc75Bkt9V4JS7KWJAAAAAElFTkSuQmCC59ef34356faa7edebc7ed5432ddb673d');
      }
      .c-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.6);
      }
      .c-modal-content {
        padding: 20px 30px;
        width: 600px;
        position: relative;
        min-height: 300px;
        margin: 5% auto 0;
        background: #fff;
      }
      .c-stretch {
        width: 100%;
        height: 100%;
      }
      .c-working-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.6);
        z-index: 15000000;
      }
      .c-iframe {
        min-height: 450px;
      }
    `;
    const style = document.createElement('style');
    style.type = 'text/css';
    if ((style as any).styleSheet) {
      (style as any).styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }
    const body = document.getElementsByTagName('body')[0];
    const head = document.head || document.getElementsByTagName('head')[0];
    (head || body).appendChild(style);
    this.cssLoaded = false;
  }

  private createLoadingOverlay() {
    if (this.loadingOverlay) {
      return this.loadingOverlay;
    }
    this.log('Creating loading overlay');
    const o = (this.loadingOverlay = document.createElement('div'));
    o.className = 'c-working-overlay c-is-hidden';
    o.id = 'c-working-overlay';
    const body = document.getElementsByTagName('body')[0];
    body.appendChild(o);
    this.ensureCSSLoaded();
    return o;
  }

  private create3DSFrame(
    url: string,
    paRequest: string,
    iframeCallbackUrl: string,
    cb: any | Function
  ) {
    let o = this.container3ds;

    if (!o) {
      const body = document.getElementsByTagName('body')[0];
      o = this.container3ds = document.createElement('div');
      o.style.backgroundColor = 'white';
      const form = (this.container3dsForm = document.createElement('form'));
      const i1 = (this.container3dsi1 = document.createElement('input'));
      const i2 = (this.container3dsi2 = document.createElement('input'));
      const _iframe = (this.container3dsFrame = document.createElement('iframe'));

      o.className = 'c-stretch';
      form.setAttribute('target', 'coinify-3dsframe');
      form.setAttribute('id', 'redirect-3ds');
      form.setAttribute('method', 'post');

      _iframe.setAttribute('name', 'coinify-3dsframe');
      _iframe.className = 'c-stretch';
      _iframe.scrolling = 'yes';
      _iframe.setAttribute(
        'style',
        'border: none; width: 100%; min-height: 350px; max-height: 550px'
      );

      i1.setAttribute('type', 'hidden');
      i1.setAttribute('name', 'PaReq');
      i2.setAttribute('type', 'hidden');
      i2.setAttribute('name', 'TermUrl');

      form.appendChild(i1);
      form.appendChild(i2);
      o.appendChild(form);
      o.appendChild(_iframe);
      body.appendChild(o);
      this.ensureCSSLoaded();
    }
    this.container3dsForm.setAttribute('action', url);
    this.container3dsi1.setAttribute('value', paRequest);
    this.container3dsi2.setAttribute('value', iframeCallbackUrl);

    let callback = cb;
    const eventHandler: EventListenerOrEventListenerObject = (event: any) => {
      const data = event.data ? event.data.toString() : '';
      let keyword;
      if (data.indexOf('[SC-Embed]') === 0) {
        keyword = '[SC-Embed]';
      } else if (data.indexOf('[ISX-Embed]') === 0) {
        keyword = '[ISX-Embed]';
      } else if (data.indexOf('[Coinify-Embed]') === 0) {
        keyword = '[Coinify-Embed]';
      } else {
        return;
      }
      window.removeEventListener('message', eventHandler);
      const msg = JSON.parse(event.data.replace(keyword, ''));
      if (msg.command === 'close') {
        if (o.parentNode) {
          o.parentNode.removeChild(o);
          if (this.containerIsOverlay) {
            this.showOverlay(false);
          }
        }
        if (callback) {
          // eslint-disable-next-line
          callback(msg.param);
          callback = undefined;
        }
      }
    };
    window.addEventListener('message', eventHandler, true);

    // Wait a litle while to submit in order to ensure that the elements just created has been added to the DOM and
    // rendered.
    setTimeout(() => {
      this.container3dsForm.submit();
    });

    return o;
  }

  private createPaymentFrame(url: string, cb: Function) {
    let o = this.containerPay;

    if (!o) {
      const body = document.getElementsByTagName('body')[0];
      o = this.containerPay = document.createElement('div');
      o.className = 'c-stretch';
      const _iframe = document.createElement('iframe');
      _iframe.setAttribute('id', 'redirect-pay');
      _iframe.setAttribute('name', 'coinify-paymentframe');
      _iframe.className = 'c-stretch c-iframe';
      _iframe.src = url;
      o.appendChild(_iframe);
      body.appendChild(o);
      this.ensureCSSLoaded();
    }

    let callback: any = cb;
    const eventHandler: EventListenerOrEventListenerObject = (event: any) => {
      const data = event.data ? event.data.toString() : '';
      if (data.indexOf('[SC-Embed]') !== 0) {
        return;
      }
      window.removeEventListener('message', eventHandler);
      const msg = JSON.parse(data.replace('[SC-Embed]', ''));
      if (msg.command === 'close') {
        if (o.parentNode) {
          o.parentNode.removeChild(o);
          if (this.containerIsOverlay) {
            this.showOverlay(false);
          }
        }
        if (callback) {
          // eslint-disable-next-line
          callback(msg.param);
          callback = undefined;
        }
      }
    };
    window.addEventListener('message', eventHandler, true);

    return o;
  }

  private showOverlay(value = true, container: any = null) {
    const overlay = this.overlay;
    if (value || value === undefined) {
      overlay.classList.remove('c-is-hidden');
      if (container) {
        container.appendChild(overlay);
      }
    } else {
      overlay.classList.add('c-is-hidden');
    }
  }

  private initSafecharge(cb: Function): void {
    this.log('Loading safecharge SDK');
    const _script = document.createElement('script');
    _script.type = 'text/javascript';
    _script.src = 'https://cdn.safecharge.com/js/v1/safecharge.js';
    _script.onload = () => {
      this.provider[PSPType.safecharge].loaded = !!this.Safecharge; // Important: this is the script referance in this context.
      this.provider[PSPType.safecharge].loading = false;
      if (!this.provider[PSPType.safecharge].loaded) {
        throw new Error('Failed to load Safecharge library');
      }
      cb(this.Safecharge);
    };
    const body = document.getElementsByTagName('body')[0];
    body.appendChild(_script);
  }

  private init_iSignThis(cb: Function): void {
    this.log('Initializing iSignThis');
    const _script = document.createElement('script');
    _script.src = this.istBaseUrl + '/js/isx-embed.js';
    _script.async = true;
    _script.onload = () => {
      this.iSignThis = _script;
      this.provider[PSPType.isignthis].loaded = !!this.Safecharge; // Important: this is the script referance in this context.
      this.provider[PSPType.isignthis].loading = false;
      cb(_script);
    };
  }

  private getScriptForProvider(pspType: string) {
    pspType = this.validatePSP(pspType);
    if (pspType === PSPType.isignthis) {
      return this.iSignThis;
    }
    return this.Safecharge;
  }

  private validatePSP(pspType: string, fallbackOnSC = true) {
    if (!pspType && fallbackOnSC) {
      console.error('Payment service provider not defined, falling back on safecharge');
      return PSPType.safecharge;
    }
    if (pspType !== PSPType.safecharge && pspType !== PSPType.isignthis) {
      throw new Error('Invalid psp :' + pspType);
    }
    return pspType;
  }

  private initPSP(pspType: string) {
    pspType = this.validatePSP(pspType);
    if (this.provider[pspType].loading) {
      throw new Error('Already loading');
    }
    return new Promise((cb, reject) => {
      if (this.provider[pspType].loaded) {
        cb(this.getScriptForProvider(pspType));
        return;
      }
      // Bootstrap SafeCharge and then execute the create token request,
      this.provider[pspType].loaded = false;
      this.provider[pspType].loading = true;
      if (pspType === PSPType.isignthis) {
        this.init_iSignThis(cb);
      } else if (pspType === PSPType.safecharge) {
        this.initSafecharge(cb);
      }
    });
  }

  /**
   * Invoke the registerCard with some info like the following.
   */
  private createTemporaryCardToken(payload: any, pspType: string) {
    pspType = this.validatePSP(pspType);
    if (pspType !== PSPType.safecharge) {
      throw new Error('createTemporary card token only supposed with safecharge');
    }
    return new Promise((resolve, reject) => {
      this.initPSP(pspType)
        .then((psp: any) => {
          // Test:
          // payload.merchantSiteId = '1811';
          // payload.environment = 'sandbox';
          this.log('Creating ccTempToken with payload ' + JSON.stringify(payload || {}));
          psp.card.createToken(payload, (e: any) => {
            resolve(e);
          });
        })
        .catch((err) => {
          console.error('Error ', err);
          reject(err);
        });
    });
  }

  /*private clearFrame() {
     TODO: add the code to ensure we can open hosted payment page again and again...
    // empty container of any existing elements
    while (iFrameContainer.firstChild) {
      iFrameContainer.removeChild(iFrameContainer.firstChild);
    }

    // add new SafeCharge iframe
    iFrameContainer.appendChild( sciFrame );

    window.addEventListener( "message", event => {
      if ( event.data.indexOf( scEmbed ) != 0 ) {
        return;
      }
      const msg = JSON.parse( event.data.replace( '[SC-Embed]', '' ) );
      if ( msg.command == 'close' ) {
        if ( msg.param == 'cancelled' ) {
          this.fire( 'cancel', null, { bubbles: false } );
        } else {
          this.fire( 'completed', null, { bubbles: false } );
        }
      }
    }, true );

  }

  // construct new SafeCharge iFrame
  public createiFrame() {
    const iframe = document.createElement('iframe');
    sciFrame.src = `${this.providerPaymentUrl}`;
    sciFrame.id = 'iframe';
    sciFrame.width = "100%";
    sciFrame.scrolling = "no";
    sciFrame.style = "border:none;";
  }*/

  private assertAccessToken(what: string) {
    if (this.accessWithTradeId) {
      throw new Error('Must have accessToken to access :' + what);
    }
  }

  private getStoreCardPayload() {
    return this.accessWithTradeId
      ? Coinify.http.get(
          this.uri(Coinify.urls.storeCardPayload2),
          this.options.accessTradeId
        )
      : Coinify.http.get(
          this.uri(Coinify.urls.storeCardPayload1),
          this.options.accessToken
        );
  }

  /**
   * Creates a permanent registration of the ccTempToken and stores an externalCardId / upoId.
   */
  private saveCardByTempToken(ccTempToken: string, sessionToken: string) {
    this.assertAccessToken('saveCardByTempToken');
    if (!ccTempToken) {
      throw new Error('invalid ccTempToken');
    }
    if (!sessionToken) {
      throw new Error('invalid sessionToken');
    }
    this.log('Saving card by temp token');
    return Coinify.http.post(
      this.uri(Coinify.urls.cards),
      {
        ccTempToken,
        sessionToken,
      },
      this.options.accessToken
    );
  }
}

export function getCoinifyInstance(): Coinify {
  let i = (window as any).coinifyInstance;
  if (!i) {
    (window as any).coinifyInstance = i = new Coinify();
  }
  return i;
}

export async function getAPMUrl(aggregateId: string) {
  return await getCoinifyInstance().getAPMUrl(aggregateId);
}

export function registerCard(options: any) {
  return getCoinifyInstance().registerCard(options);
}

export function handleTradePaymentInfo(
  createTradeResponseTransferInDetails: any,
  container: any = null
) {
  const details = createTradeResponseTransferInDetails;
  const coinify = getCoinifyInstance();
  coinify.log('Handle trade payment info ', details);

  if (details.acsUrl || details.paRequest) {
    return coinify.open3DSecureUrlForTradeAndFinalizePayment(details, container);
  }

  return coinify.openPaymentUrl(
    {
      url: details.redirectUrl,
      is3DS: false,
      callbackUrl: details.returnUrl || Coinify.urls.hostedPaymentPageCallback,
      paRequest: undefined,
    },
    details.provider,
    container
  );
}

export function open3DSecureUrlForTrade(
  createTradeResponseTransferInDetails: any,
  container: any = null
) {
  if (!createTradeResponseTransferInDetails.acsUrl) {
    throw new Error('Missing ascUrl');
  }
  if (!createTradeResponseTransferInDetails.paRequest) {
    throw new Error('Missing paRequest');
  }
  return getCoinifyInstance().open3DSecureUrlForTrade(
    createTradeResponseTransferInDetails,
    container
  );
}

export function applyCardToTradeTransferInDetails(tradeInfo: any, atbs: any) {
  return Coinify.applyCardToTradeTransferInDetails(tradeInfo, atbs);
}

export function openHostedPaymentPage(
  url: string,
  provider = 'safecharge',
  container?: any
) {
  const args = {
    url,
    is3DS: false,
    callbackUrl: Coinify.urls.hostedPaymentPageCallback,
    paRequest: undefined,
  };
  return getCoinifyInstance().openPaymentUrl(args, provider, container);
}

export function getCardList() {
  return getCoinifyInstance().getCardList();
}

export function setOptions(opts: any) {
  return getCoinifyInstance().setOptions(opts);
}

export function createTradePaymentAttempt(opts: any) {
  return getCoinifyInstance().createTradePaymentAttempt(opts);
}

export function finalizePayment(params: any, container: any = null): Promise<any> {
  const coinify = getCoinifyInstance();

  const finalizeTradeArgs = {
    paResponse: params.paResponse,
    tradeId: params.tradeId,
    CVV: params.CVV,
  };

  Object.keys(finalizeTradeArgs).forEach((x) => {
    if (!finalizeTradeArgs[x]) {
      coinify.log('Missing argument ' + x + ' in ', params);
      throw new Error('Missing argument ' + x);
    }
  });

  return coinify.finalizePayment(finalizeTradeArgs);
}

export function setAccessInfo(accessInfo: {
  type: 'bearer-token' | 'trade-id';
  value: string;
}) {
  getCoinifyInstance();
  if (!accessInfo.value) {
    console.error('invalid tradeId');
    return;
  }
  if (accessInfo.type === 'bearer-token') {
    setOptions({accessTradeId: null, accessToken: accessInfo.value});
  } else if (accessInfo.type === 'trade-id') {
    setOptions({accessToken: null, accessTradeId: accessInfo.value});
  }
}

export function getPaymentAttemptState(paymentId: string) {
  return getCoinifyInstance().getPaymentAttemptState(paymentId);
}
