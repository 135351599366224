export interface CardData {
  cardNumber: string;
  cardHolderName: string;
  expirationMonth: string;
  expirationYear: string;
  CVV: string;
}

export const validateCardData = (card: CardData) => {
  if (
    !card.cardNumber ||
    !card.cardHolderName ||
    !card.CVV ||
    !card.expirationYear ||
    !card.expirationMonth
  ) {
    if (!card.cardNumber) {
      throw new Error('Invalid card data - Missing card number');
    }
    if (!card.cardHolderName) {
      throw new Error('Invalid card data - Missing card holder name');
    }
    if (!card.CVV) {
      throw new Error('Invalid card data - Missing CVV');
    }
    if (!card.expirationYear) {
      throw new Error('Invalid card data - Missing expiration year');
    }
    if (!card.expirationMonth) {
      throw new Error('Invalid card data - Missing expiration month');
    }
    throw new Error('Invalid card data');
  }
};
