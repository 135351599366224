import React, {useContext, useState, useEffect} from 'react';
import {QueryContext} from 'src/api/QueryContext';
import {PaymentContainer} from './PaymentContainer';
import {APMSelection, APMComponent} from './APM';
import {PaymentController} from 'src/PaymentController';

export const PaymentPage = () => {
  const {isAPMEnabled} = useContext(QueryContext);
  const [displayAPM, setDisplayAPM] = useState(false);
  const [madeSelection, setMadeSelection] = useState(false);

  useEffect(() => {
    PaymentController.initialize();
  }, []);

  return (
    <div>
      {isAPMEnabled && !madeSelection && (
        <APMSelection
          chooseApm={(result) => {
            setDisplayAPM(result);
            setMadeSelection(true);
          }}
        />
      )}
      {displayAPM && <APMComponent />}
      {(!isAPMEnabled || (!displayAPM && madeSelection)) && <PaymentContainer />}
    </div>
  );
};

export default PaymentPage;
