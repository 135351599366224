import * as queryString from 'query-string';

export class Config {
  public static get verbose(): boolean {
    return Config.get().verbose;
  }

  public static get env(): string {
    if (Config.isDevelopment()) {
      return 'development';
    } else if (Config.isSandbox()) {
      return 'sandbox';
    }
    return 'production';
  }

  public static get shouldUseHeartbeatLoader(): boolean {
    // Can be added to a seperate method if needed to ge the partner class.
    const clsId = Config.getPartnerClassId();
    if (clsId === Config.CLS_BLOCKCHAIN) {
      return true;
    }

    // Per default the retry / cancel page is disabled.
    return false;
  }

  /**
   * This is where a partner can be configured to use a cancel retry page to allow retries in the end.
   */
  public static get shouldShowRetryCancelPage(): boolean {
    // Can be added to a seperat method if needed to ge the partner class.
    const clsId = Config.getPartnerClassId();
    if (![Config.CLS_BLOCKCHAIN, Config.CLS_OLD_PARTNERS].includes(clsId)) {
      return true;
    }

    // Per default the retry / cancel page is disabled.
    return false;
  }
  public static CLS_OTHER_PARTNER = 0;
  public static CLS_COINIFY = 1;
  public static CLS_BLOCKCHAIN = 2;
  public static CLS_BRD = 3;
  public static CLS_OLD_PARTNERS = 4;

  public static getPartnerId(partnerName: string) {
    if (partnerName === 'coinify') {
      return 11; // Always 11 no matter the environment.
    } else if (partnerName === 'bettercoins') {
      return Config.isSandbox() || Config.isDevelopment() ? 54 : 74;
    } else if (partnerName === 'employee') {
      return Config.isSandbox() || Config.isDevelopment() ? 55 : 76;
    }
    throw new Error('Invalid partner : ' + partnerName);
  }

  public static getPartnerClassId(): number {
    const {partnerId} = Config.get();

    // Can be added to a seperat method if needed to ge the partner class.
    let clsId = Config.CLS_OTHER_PARTNER;
    const COINIFY_APP_IDS = [
      11, // Coinify app ( myCoinify ) sandbox and production
      32, // Coinify trade test - prod
      54, // Bettercoins sandbox
      74, // Bettercoins production
      55, // Coinify employee sandbox
      76, // Coinify employee production
    ];
    const BLOCKCHAIN_IDS = [
      18, // Blockchin thing production
      19, // Blockchain sandbox
      24, // Blockchain sandbox
      35, // Blockchain testnet ?
      36, // Blockchain production
    ];
    const OLD_PARTNER_IDS = [
      34, // Abra sandbox
      66, // Abra prod
      57, // Argent labs sandbox
      80, // Argent labs prod
    ];
    const BRD_IDS = [
      51, // BRD Staging
      68, // BRD Production
    ];
    if (COINIFY_APP_IDS.includes(partnerId)) {
      clsId = Config.CLS_COINIFY;
    } else if (BLOCKCHAIN_IDS.includes(partnerId)) {
      clsId = Config.CLS_BLOCKCHAIN;
    } else if (BRD_IDS.includes(partnerId)) {
      clsId = Config.CLS_BRD;
    } else if (OLD_PARTNER_IDS.includes(partnerId)) {
      clsId = Config.CLS_OLD_PARTNERS;
    }
    return clsId;
  }

  static asString = (queryVar: string | string[]) =>
    typeof queryVar === 'string' ? (queryVar as string) : '';

  static asBoolean = (queryVar: string | string[]) =>
    ['1', 'true'].indexOf(Config.asString(queryVar).toLocaleLowerCase()) >= 0;

  static asStringArray = (queryVar: string | string[]) =>
    !queryVar ? [] : typeof queryVar === 'string' ? [queryVar] : queryVar;

  static asInt = (queryVar: string | string[]) =>
    Number.parseInt(Config.asString(queryVar));

  static asFloat = (queryVar: string | string[]) =>
    Number.parseFloat(Config.asString(queryVar));

  // TODO: Consider adding this to configuration.
  static getDefaultUrl() {
    if (Config.isDevelopment()) {
      return 'https://app-api.sandbox.coinify.com'; // KM: If you want to test against sandbox use this line ( makes more sense when developing for my setup )
      // return 'http://localhost:8080';
      // return 'http://localhost:8221';
    } else if (Config.isSandbox()) {
      return 'https://app-api.sandbox.coinify.com';
    }
    return 'https://app-api.coinify.com';
  }

  public static get() {
    const getQueryVar = Config.getQuery();

    const accessToken = Config.asString(getQueryVar('accessToken'));

    const coinifyApiBaseUrl = decodeURIComponent(
      Config.asString(getQueryVar('baseUrl') || Config.getDefaultUrl())
    );

    const partnerId = Config.asInt(getQueryVar('partnerId')) || 36;
    const partnerName = Config.asString(getQueryVar('partnerName')) || 'Partner';
    const tradeId = Config.asString(getQueryVar('tradeId'));

    const verbose =
      ['1', 'true'].indexOf(Config.asString(getQueryVar('verbose')).toLowerCase()) >= 0;

    let testCard = Config.asInt(getQueryVar('testCard'));
    testCard = isFinite(testCard) ? testCard : 0;
    const testCardInvalid =
      ['1', 'true'].indexOf(Config.asString(getQueryVar('testCardInvalid'))) >= 0;

    const transferInAmount = Config.asFloat(getQueryVar('transferInAmount'));

    const transferInHandlingFeeAmount = Config.asFloat(
      getQueryVar('transferInHandlingFee')
    );

    const transferInFeeAmount = Config.asFloat(getQueryVar('transferInFeeAmount'));

    const transferInCurrency = Config.asString(getQueryVar('transferInCurrency'));

    const transferOutAmount = Config.asFloat(getQueryVar('transferOutAmount'));

    const transferOutFeeAmount = Config.asFloat(getQueryVar('transferOutFeeAmount'));

    const transferOutCurrency = Config.asString(getQueryVar('transferOutCurrency'));

    const transactionTime = Config.asString(getQueryVar('transactionTime')) || '1';
    const paymentId = Config.asString(getQueryVar('paymentId'));

    const email = Config.asString(getQueryVar('email'));
    const country = Config.asString(getQueryVar('country'));
    const billingAddress = {email, country};

    const returnUrl = decodeURI(Config.asString(getQueryVar('returnUrl')));

    const theme = Config.asString(getQueryVar('theme'));

    const supportedCards = Config.asStringArray(getQueryVar('supportedCards'));

    return {
      accessToken,
      coinifyApiBaseUrl,
      partnerId,
      partnerName,
      tradeId,
      billingAddress,
      verbose,
      testCard,
      testCardInvalid,
      theme,
      transferInAmount,
      transferInFeeAmount,
      transferInHandlingFeeAmount,
      transferInCurrency,
      transferOutAmount,
      transferOutFeeAmount,
      transferOutCurrency,
      transactionTime,
      paymentId,
      returnUrl,
      supportedCards,
    };
  }

  private static getQuery = () => {
    const queryObject = queryString.parse(window.location.search);

    return (varName: string) => {
      const value = queryObject[varName];

      if (!value) {
        console.warn('Query variable %s not found', varName);
      }

      return value;
    };
  };

  private static isDevelopment() {
    return window.location.href.toLowerCase().indexOf('localhost') >= 0;
  }

  private static isSandbox(): boolean {
    return window.location.href.toLowerCase().indexOf('sandbox') >= 0;
  }
}
