import * as React from 'react';
import './App.css';
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import {QueryProvider} from './api/QueryContext';
import PaymentPage from './components/PaymentPage';

const App = () => (
  <Router>
    <Switch>
      <QueryProvider>
        <PaymentPage />
      </QueryProvider>
    </Switch>
  </Router>
);

export default App;
