import {BehaviorSubject, Subject} from 'rxjs';

import {CoinifyPSPLib} from './coinify/CoinifyPSPLib';
import {Config} from './Config';
import {Logger} from './Logger';
import {BillingAddress} from './models';

interface RegisterCardResult {
  cardExternalId?: string;
  ccTempToken?: string;
  CVV: string;
  sessionToken?: string;
  bin?: string;
  lastFour?: string;
  name?: string;
}

interface ThreeDSecureObject {
  acsUrl: string;
  paRequest: string;
  threeDSecureCallback: string;
}

export interface TradePayment {
  details: ThreeDSecureObject;
}

// eslint-disable-next-line
export interface HandleTradePaymentResult {}

export type OnPaymentResultFunction = (result: HandleTradePaymentResult) => void;

export interface PaymentStateObject {
  state: number;
  message: string;
}

/**
 * This is our controller class, IN flux termanology this would be refered to as PaymentStore.
 * It represents the state of the payment class and emits events event the state chanages.
 * The UI listens to these events and reacts accordingly.
 */
export class PaymentController {
  public static MSG_UNKNOWN_ERROR = 'Unknown error';
  public static MSG_FAILED_TO_SUBMIT_CARD_DETAILS =
    'Failed to submit card details. Please validate card details and try again.';
  public static MSG_NO_TRADE_ID = 'Trade id not provided.';
  public static MSG_FAILED_TO_HANDLE_PAYMENT = 'Failed to handle payment.';
  public static MSG_INVALID_PAY_RESPONSE = 'Invalid payment response from service.';
  public static MSG_GENERAL_PAYMENT_ERROR = 'Failed to complete your payment.';
  public static MSG_INVALID_CARD_NUMBER =
    'Invalid card number - Please check the card details and try again.';
  public static MSG_INVALID_CVV_NUMBER =
    'Invalid CVV number - Please check the card details and try again.';

  public static PAYMENT_STATE_REGISTER_CARD = 0;
  public static PAYMENT_STATE_PROCESSING = 1;
  public static PAYMENT_STATE_SUCCESS = 2;
  public static PAYMENT_STATE_FAILED = 3;
  public static PAYMENT_STATE_DECLINED = 4;
  public static PAYMENT_STATE_DECLINED_NO_3DS = 5;

  public static state = {
    cvv: '',
    tradeId: '',
    errorMessage: '',
    saveCard: false,
  };

  public static tradeId = new Subject();
  public static errorMessage = new BehaviorSubject('');
  public static paymentState = new BehaviorSubject({
    state: PaymentController.PAYMENT_STATE_REGISTER_CARD,
    message: '',
  } as PaymentStateObject);
  public static reset = new Subject();

  private static PAYMENT_ATTEMPT_PENDING = 'pending';
  private static PAYMENT_ATTEMPT_DECLINED = 'declined';
  private static PAYMENT_ATTEMPT_COMPLETED = 'completed';

  public static getUnsupportedCardMessage(cardType) {
    return (
      String(cardType || 'Unknown card') +
      ' cards is not supported. Please try again with a VISA or Mastercard'
    );
  }

  public static setCVV(cvv: string) {
    PaymentController.state.cvv = cvv;
  }

  /**
   * The payment page uses the tradeId to access payment related functionality.
   */
  public static setTradeId(tradeId: string) {
    PaymentController.state.tradeId = tradeId;
    PaymentController.tradeId.next(PaymentController.state.tradeId);
  }

  public static getCVV(): string {
    return PaymentController.state.cvv;
  }

  public static getTradeId(): string {
    return PaymentController.state.tradeId;
  }

  public static showError(text: string) {
    PaymentController.state.errorMessage = text;
    PaymentController.errorMessage.next(PaymentController.state.errorMessage);
  }

  /**
   * Initialize the entire payment page.
   */
  public static initialize() {
    const {coinifyApiBaseUrl, verbose, tradeId} = Config.get();
    CoinifyPSPLib.setOptions({coinifyApiBaseUrl, verbose});
    CoinifyPSPLib.setAccessInfo({type: 'trade-id', value: tradeId});
    PaymentController.setTradeId(tradeId);
  }

  /**
   * One stop to register the credit card infomation and returns a token.
   */
  public static async registerCardAndCreateTradePayment(
    cardHolderName: string,
    cardNumber: string,
    expirationMonth: string,
    expirationYear: string,
    cvv: string,
    email: string,
    country: string
  ) {
    const registerCardResult = await PaymentController.registerCard(
      cardHolderName,
      cardNumber,
      expirationMonth,
      expirationYear,
      cvv,
      email,
      country
    );
    const tradePaymentResult = await PaymentController.createTradePaymentAttempt(
      registerCardResult
    );
    return tradePaymentResult;
  }

  /**
   * Invoked with the tradePayment result in order to process the trade payment.
   */
  public static processTradePayment(tradePaymentResult: any) {
    (async () => {
      PaymentController.paymentState.next({
        state: PaymentController.PAYMENT_STATE_PROCESSING,
        message: '',
      });

      const threeDSecureResult = await PaymentController.open3DSecureUrlForTrade(
        tradePaymentResult
      );

      const finalizePaymentResult = await PaymentController.finalizePayment(
        threeDSecureResult
      );

      if (!finalizePaymentResult) {
        throw new Error('Failed to finalize payment');
      }

      if (finalizePaymentResult.error) {
        throw new Error(finalizePaymentResult.error_description || 'unknown error');
      }

      // Returns a timestamp in ms.
      const getTimestamp = () => {
        return new Date().getTime();
      };

      // Async function to for for a duration.
      const sleep = async (ms: number) => {
        return new Promise<any>((resolve) => {
          window.setTimeout(resolve, ms);
        });
      };

      const ts = getTimestamp();
      let doLoop = true;
      let ok = false;
      let message = '';
      while (doLoop) {
        if (getTimestamp() - ts > 30000) {
          Logger.error('Timed out waiting for payment state.');
          doLoop = false;
          ok = false;
          continue;
        }
        await sleep(50);
        const paymentAttemptState = await PaymentController.getPaymentAttemptState();
        const NO_RESPONSE = 'no-response-from-api';
        const state = paymentAttemptState ? paymentAttemptState.state : NO_RESPONSE;
        message = paymentAttemptState ? paymentAttemptState.message : 'no message';
        if (state === PaymentController.PAYMENT_ATTEMPT_PENDING) {
          // continue in 100 ms
          await sleep(100);
        } else if (state === PaymentController.PAYMENT_ATTEMPT_DECLINED) {
          doLoop = false;
          ok = false;
          continue;
        } else if (state === PaymentController.PAYMENT_ATTEMPT_COMPLETED) {
          doLoop = false;
          ok = true;
          continue;
        } else if (state === NO_RESPONSE) {
          Logger.error('Failed to retrive payment attempt state from service.');
          await sleep(3000);
          continue;
        } else {
          Logger.error('Unexpected payment attempt state ', state);
        }
      }

      if (!ok) {
        throw new Error(message);
      }
    })()
      .then((response) => {
        PaymentController.paymentState.next({
          state: PaymentController.PAYMENT_STATE_SUCCESS,
          message: '',
        });
      })
      .catch((err) => {
        Logger.error(err);
        const error_description =
          err && err.error_description
            ? err.error_description
            : PaymentController.MSG_GENERAL_PAYMENT_ERROR;
        PaymentController.showError(error_description);
        PaymentController.paymentState.next({
          state: PaymentController.PAYMENT_STATE_DECLINED,
          message: error_description,
        });
      });
  }

  /**
   * Invoked in order to reset the whole state of the payment session
   * so we can try again.
   */
  public static resetToRetry() {
    PaymentController.reset.next();
    PaymentController.errorMessage.next('');
    PaymentController.paymentState.next({
      state: PaymentController.PAYMENT_STATE_REGISTER_CARD,
      message: '',
    });
  }

  /**
   * Wraps the getPaymentAttemptState and calls it with the correct paymentId.
   */
  private static async getPaymentAttemptState() {
    const {paymentId} = Config.get();
    return CoinifyPSPLib.getPaymentAttemptState(paymentId);
  }

  /**
   * Invokes the register Card method in the PSP library in and writes the result to the state of the payment controller.
   */
  private static async registerCard(
    cardHolderName: string,
    cardNumber: string,
    expirationMonth: string,
    expirationYear: string,
    cvv: string,
    email: string,
    country: string
  ): Promise<RegisterCardResult> {
    cardNumber = cardNumber.replace(/\s/g, '');
    return new Promise<RegisterCardResult>((resolve, reject) => {
      // Prevent default submit, reloading page
      const saveCard = PaymentController.state.saveCard;
      // Process the card data to unify the format.
      PaymentController.state.cvv = cvv;
      const card = Object.assign(
        {},
        {
          cardHolderName,
          cardNumber,
          expirationMonth,
          expirationYear,
          CVV: cvv,
        }
      );

      const billingAddress = {email, country} as BillingAddress;

      const cardBin = card.cardNumber.trim().replace(' ', '').slice(0, 6);

      const lastFour = card.cardNumber.trim().slice(-4);

      // 15-02-2019: KRM->Nikica/Grant/Nathan: This could maybe be a good place to add the GO-NO-GO request
      // to send the bin etc to our services for risk evaluation. ( Before we sent it off to safecharge or any other partners. )

      CoinifyPSPLib.registerCard({billingAddress, card, saveCard})
        .then((registerCardResponse) => {
          const ret = {} as RegisterCardResult;
          ret.bin = cardBin;
          ret.lastFour = lastFour;
          ret.name = card.cardHolderName.trim();

          if (saveCard) {
            ret.cardExternalId = registerCardResponse.cardExternalId;
            ret.CVV = card.CVV;
            if (!ret.cardExternalId || !ret.CVV) {
              Logger.error(
                'Failed to retrieve cardExternalId or CVV from response ',
                registerCardResponse
              );
            }
          } else {
            ret.ccTempToken = registerCardResponse.ccTempToken;
            ret.CVV = card.CVV;
            ret.sessionToken = registerCardResponse.sessionToken;
            if (!ret.ccTempToken || !ret.sessionToken || !ret.CVV) {
              Logger.error('ccTempToken|sessionToken|CVV not in response', ret);
            }
          }
          resolve(ret);
        })
        .catch((err) => {
          Logger.error(err);
          PaymentController.showError(
            err.error_description ||
              err.errorMessage ||
              PaymentController.MSG_FAILED_TO_SUBMIT_CARD_DETAILS
          );
          reject(err);
        });
    });
  }

  /**
   * Wraps the createPaymentAttempt and handle errors.
   */
  private static async createTradePaymentAttempt(
    card: RegisterCardResult
  ): Promise<TradePayment> {
    const {paymentId} = Config.get();
    const opts = Object.assign({paymentId}, card as any);
    try {
      const result = await CoinifyPSPLib.createTradePaymentAttempt(opts);
      return result;
    } catch (e) {
      PaymentController.state.errorMessage =
        e.error_description || e.errorMessage || PaymentController.MSG_UNKNOWN_ERROR;
      PaymentController.showError(PaymentController.state.errorMessage);
      throw e;
    }
  }

  /**
   * Wraps the open 3DS method of the Coinify PSP library and ensures errors are thrown if it fails.
   */
  private static async open3DSecureUrlForTrade(
    options: TradePayment
  ): Promise<HandleTradePaymentResult> {
    if (!PaymentController.state.cvv || !PaymentController.state.tradeId) {
      Logger.error('No CVV or TradeId ', PaymentController.state);
      PaymentController.showError(PaymentController.MSG_FAILED_TO_HANDLE_PAYMENT);
      return;
    }
    if (
      !options.details ||
      !options.details.acsUrl ||
      !options.details.paRequest ||
      !options.details.threeDSecureCallback
    ) {
      Logger.error(
        'Missing details | details.acsUrl | details.paRequest | details.threeDSecureCallback in',
        options
      );
      PaymentController.showError(PaymentController.MSG_INVALID_PAY_RESPONSE);
      return;
    }
    try {
      return await CoinifyPSPLib.open3DSecureUrlForTrade(
        {
          acsUrl: options.details.acsUrl,
          paRequest: options.details.paRequest,
          CVV: PaymentController.getCVV(),
          tradeId: PaymentController.getTradeId(),
          threeDSecureCallback: options.details.threeDSecureCallback,
        } as HandleTradePaymentResult,
        null
      );
    } catch (e) {
      PaymentController.showError(
        e.error_description || e.errorMessage || PaymentController.MSG_UNKNOWN_ERROR
      );
      throw e;
    }
  }

  /**
   * Wrapping the finalize payment method of the PSP library in order to ensure that errors gets displayed if
   * any errors are present.
   */
  private static async finalizePayment(params: any) {
    if (!PaymentController.state.cvv || !PaymentController.state.tradeId) {
      PaymentController.showError(PaymentController.MSG_FAILED_TO_HANDLE_PAYMENT);
      return;
    }
    if (!params.paResponse || !params.tradeId) {
      Logger.error('Missing params.paResponse | params.tradeId in', params);
      PaymentController.showError(PaymentController.MSG_UNKNOWN_ERROR);
      return;
    }

    return await CoinifyPSPLib.finalizePayment(params, null);
  }
}
