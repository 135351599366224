import {
  applyCardToTradeTransferInDetails,
  createTradePaymentAttempt,
  finalizePayment,
  getCardList,
  getPaymentAttemptState,
  handleTradePaymentInfo,
  open3DSecureUrlForTrade,
  openHostedPaymentPage,
  registerCard,
  setAccessInfo,
  setOptions,
  getAPMUrl,
} from './Coinify';

export class CoinifyPSPLib {
  public static setOptions = setOptions;
  public static getCardList = getCardList;
  public static openHostedPaymentPage = openHostedPaymentPage;
  public static applyCardToTradeTransferInDetails = applyCardToTradeTransferInDetails;
  public static handleTradePaymentInfo = handleTradePaymentInfo;
  public static registerCard = registerCard;
  public static createTradePaymentAttempt = createTradePaymentAttempt;
  public static open3DSecureUrlForTrade = open3DSecureUrlForTrade;
  public static finalizePayment = finalizePayment;
  public static setAccessInfo = setAccessInfo;
  public static getPaymentAttemptState = getPaymentAttemptState;
  public static getAPMUrl = getAPMUrl;
}
