import React from 'react';

export const APMSelection = ({chooseApm}: {chooseApm: (arg: boolean) => void}) => (
  // this guerilla styling should be managed todo
  <div className='container grid column'>
    <p className='apm-message'>
      You can complete the payment using an alternative payment option. Would you like to
      try?
    </p>
    <div className='grid row guttered'>
      <button id='pay-apm' className='button primary' onClick={() => chooseApm(true)}>
        Pay With APM
      </button>
      <button id='pay-card' className='button default' onClick={() => chooseApm(false)}>
        Pay With Card
      </button>
    </div>
  </div>
);

export default APMSelection;
