import {Logger} from './Logger';

/**
 * The Cross frame communication class ensures that the
 * parent page will receive a backwards compatible protocol of iframe messages
 * so that Blockchain.info's wallet integration works out of the box with the payment page.
 */
export class CrossFrameCommunication {
  // Message Types
  public static EVENT_TYPE_COMPLETE = 'complete';

  // Messages
  public static EVENT_COMPLETE_SUCCESS = 'SUCCESS';
  public static EVENT_COMPLETE_EXPIRED = 'EXPIRED';
  public static EVENT_COMPLETE_REJECTED = 'REJECTED';
  public static EVENT_COMPLETE_FAILED = 'FAILED';
  public static EVENT_COMPLETE_CANCELLED = 'CANCELLED';
  public static EVENT_COMPLETE_PENDING = 'PENDING';

  public static postMessage(eventType: string, eventName: string) {
    Logger.log('Posting message to host type', eventType, 'name', eventName);
    const msg = {event: eventType, state: eventName};
    window.parent.postMessage('[ISX-Embed]' + JSON.stringify(msg), '*');
  }

  public static postCompleteWithSuccess() {
    CrossFrameCommunication.postMessage(
      CrossFrameCommunication.EVENT_TYPE_COMPLETE,
      CrossFrameCommunication.EVENT_COMPLETE_SUCCESS
    );
  }

  public static postCancel() {
    CrossFrameCommunication.postMessage(
      CrossFrameCommunication.EVENT_TYPE_COMPLETE,
      CrossFrameCommunication.EVENT_COMPLETE_CANCELLED
    );
  }
}
