export default class CoinifyHttp {
  public get(url: string, accessToken: any = '') {
    return new Promise((callback, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url);
      if (accessToken && accessToken !== '') {
        // xhr.withCredentials = true;
        xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
      }
      xhr.setRequestHeader('Content-Type', 'application/json');
      const STATUS_READY = 4;
      const STATUS_200 = 200;
      const STATUS_0 = 0;
      xhr.onreadystatechange = () => {
        if (xhr.readyState === STATUS_READY) {
          if (
            xhr.status === STATUS_200 ||
            (xhr.status === STATUS_0 && xhr.responseText !== '')
          ) {
            // eslint-disable-next-line
            callback(JSON.parse(xhr.responseText || '{}'));
          } else {
            reject({
              url,
              status: xhr.status,
              body: xhr.responseText || '',
            });
          }
        }
      };
      xhr.send();
    });
  }

  public post(url: string, values: any = {}, accessToken = '') {
    return new Promise((callback, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', url);
      if (accessToken && accessToken !== '') {
        xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
      }
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const statusOk = xhr.status === 200 || xhr.status === 201;
          if (statusOk || (xhr.status === 0 && xhr.responseText !== '')) {
            // eslint-disable-next-line
            callback(JSON.parse(xhr.responseText) || {});
          } else {
            reject({
              url,
              status: xhr.status,
              body: xhr.responseText || '',
            });
          }
        }
      };
      xhr.send(JSON.stringify(values));
    });
  }
}
