import * as React from 'react';
import {Subscription} from 'rxjs';

import {PaymentController, PaymentStateObject} from '../../PaymentController';
import {CardDataForm} from '../CardDataForm';
import {PaymentProcessing} from '../PaymentProcessing';

import './PaymentContainer.css';

/**
 * This class encapsules the root and core logic of the payment page using safecharge as a payment processor.
 *
 * There are other types of payment integration modes like iSignThis hosted solution which require us to implement
 * other types of integration techniques ( iframe ).
 */
export class PaymentContainer extends React.Component {
  public state = {
    paymentState: PaymentController.PAYMENT_STATE_REGISTER_CARD,
    errorMessage: '',
  };

  public subscription: Subscription;

  public componentDidMount() {
    this.subscription = PaymentController.paymentState.subscribe(
      ({state, message}: PaymentStateObject) => {
        this.setState({paymentState: state, errorMessage: message || ''});
      }
    );
  }

  public componentWillUnmount() {
    this.subscription.unsubscribe();
    this.subscription = null;
  }

  public render() {
    return (
      <div className='payment-container'>
        {this.state.paymentState === PaymentController.PAYMENT_STATE_REGISTER_CARD && (
          <CardDataForm />
        )}
        {this.state.paymentState >= PaymentController.PAYMENT_STATE_PROCESSING && (
          <PaymentProcessing />
        )}
      </div>
    );
  }
}
