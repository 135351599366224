import * as React from 'react';

import './DotLoader.css';

interface DotLoaderProps {
  widthAndHeight: number;
  bgColor: string;
}

export class DotLoader extends React.Component<DotLoaderProps, any> {
  private bgColor = 'white';
  constructor(props: DotLoaderProps) {
    super(props);
    this.bgColor = props.bgColor || null;
  }
  public render() {
    return (
      <div className='loading-dots'>
        <div className='loading-dots--dot' style={{backgroundColor: this.bgColor}} />
        <div className='loading-dots--dot' style={{backgroundColor: this.bgColor}} />
        <div className='loading-dots--dot' style={{backgroundColor: this.bgColor}} />
      </div>
    );
  }
}
