import React, {useContext, useEffect, useState} from 'react';
import {QueryContext} from 'src/api/QueryContext';
import {CoinifyPSPLib} from '../../coinify/CoinifyPSPLib';

export const APMComponent = () => {
  const {paymentAggregateId} = useContext(QueryContext);
  const [apmUrl, setApmUrl] = useState('');

  useEffect(() => {
    CoinifyPSPLib.getAPMUrl(paymentAggregateId).then((url) => setApmUrl(url));
  }, [paymentAggregateId, setApmUrl]);
  return apmUrl ? (
    <iframe
      id='apm-iframe'
      src={apmUrl}
      title='Alternative Payment Option Window'
    ></iframe>
  ) : null;
};

export default APMComponent;
