export interface UrlData {
  is3DS: boolean;
  url: string;
  callbackUrl: string;
  paRequest: string;
}

export const validateUrlData = (urlData: UrlData) => {
  if (
    urlData.is3DS === undefined ||
    !urlData.url ||
    !urlData.callbackUrl ||
    (urlData.is3DS && !urlData.paRequest)
  ) {
    throw new Error('Invalid url data');
  }
};
