import {Config} from './Config';

export class Logger {
  public static log(...args: any[]) {
    if (Config.verbose) {
      console.error(...(arguments as any));
    }
  }

  public static error(...args: any[]) {
    if (Config.verbose) {
      console.error(...(arguments as any));
    }
  }

  public static warn(...args: any[]) {
    if (Config.verbose) {
      console.error(...(arguments as any));
    }
  }
}
