import IMask from 'imask';
import * as React from 'react';
import './Form.css';

interface InputProps {
  title?: string;
  name?: string;
  id?: string;
  type?: string;
  value?: string;
  onInput?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  className?: string;
  mask?: any;
  onMaskComplete?: (arg: any) => void;
  onMaskAccept?: (arg: any) => void;
  pattern?: string;
}

interface InputState {
  complete: boolean;
  value: string;
}

export class Input extends React.Component<InputProps, InputState> {
  public state = {
    complete: false,
    value: '',
  };
  private maskRef;
  private selfRef;

  constructor(props: InputProps) {
    super(props);
    this.selfRef = React.createRef();
  }

  public onRef(e) {
    let oldSelf = {};
    oldSelf = e;
    this.selfRef = e;
    if (this.props.mask) {
      if (oldSelf && oldSelf !== e && this.props.mask && this.maskRef) {
        // this.reinitMask();
      } else if (!this.maskRef) {
        this.initMask();
      }
    }
  }

  public initMask() {
    if (!this.selfRef) {
      console.error('Error input element referance not set.');
      return;
    }
    if (!this.props.mask) {
      console.error(
        'Error cannot initialize Input field with mask when no mask is given.'
      );
      return;
    }

    this.maskRef = new IMask(this.selfRef, {
      mask: this.props.mask,
      dispatch(appended, dynamicMasked) {
        const numbersInMask = (dynamicMasked.value + appended).replace(/\D/g, '');
        for (const mask of dynamicMasked.compiledMasks) {
          const re = new RegExp(mask.regex);
          if (numbersInMask.match(re) !== null) {
            return mask;
          }
        }
      },
    });
    this.maskRef
      .on('accept', () => {
        this.onMaskAccept();
      })
      .on('complete', () => {
        this.onMaskComplete();
      });
  }

  public destroyMask() {
    if (this.maskRef) {
      this.maskRef.destroy();
      delete this.maskRef;
    }
  }

  public onMaskAccept() {
    if (this.props.onMaskAccept) {
      this.props.onMaskAccept(this.maskRef);
    }
  }

  public onMaskComplete() {
    this.setState({complete: true});
    if (this.props.onMaskComplete) {
      this.props.onMaskComplete(this.maskRef);
    }
  }

  public render() {
    const props = this.props;
    return (
      <input
        ref={(e) => this.onRef(e)}
        className={props.className || 'form-input'}
        id={props.id || ''}
        name={props.name}
        type={props.type}
        value={props.value}
        onInput={props.onInput}
        placeholder={props.placeholder}
      />
    );
  }
}
